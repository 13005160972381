import React, { useState } from 'react'
import "./form.css";
import API_BASE from '../config';

export default function Form() {
  const [nomComplet, setNomComplet] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [message, setMessage] = useState("");
  const [error, setError] = useState("");

  const onSubmit = (e) => {
    e.preventDefault();
    fetch(API_BASE + "/leads/create", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        "fullName": nomComplet,
        "email": email,
        "phone": phone,
        "message": message
      })
    })
      .then(response => response.json())
      .then(data => {
        console.log(data)
        setEmail("");
        setPhone("");
        setMessage("");
        setNomComplet("");
        setError("Votre formulaire a été envoyé avec succès. Merci d'avoir fourni vos informations")
      })
      .catch((err) => {
        console.log(err)
        setEmail("");
        setPhone("");
        setMessage("");
        setNomComplet("");
        setError('Nous avons rencontré un problème lors du traitement de votre soumission. Veuillez vérifier vos entrées et réessayer')
      })
  }
  const onTryAgain = () => {
    setError("");
    setEmail("");
    setPhone("");
    setMessage("");
    setNomComplet("");
  }
  return (
    <div className='form-container' id='form'>
      <div>
        <img className='questions-mark' src="/images/questionMark.png" alt="" />
        <h3>Avez-vous des questions ou besoin<span> d'assistance</span> ?</h3>
        <p>Remplissez le formulaire ci-dessous et l'un de nos conseillers vous répondra dans les plus brefs délais.</p>
      </div>
      {error ?
        <>
          {
            error.includes("succès") ?
              <div className="alert-success">
                <p>
                  {error}
                </p>
                <button className="check-types">
                  <a href="#types">
                    Voir les types
                  </a>
                </button>
              </div>
              :
              <div className="alert-danger">
                <p>
                  {error}
                </p>
                <button onClick={onTryAgain}>
                  réessayer
                </button>
              </div>
          }
        </>
        :
        <form action="" className='form' onSubmit={(e) => onSubmit(e)}>
          <input type="text"
            placeholder='Nom complet'
            value={nomComplet}
            onChange={(e) => setNomComplet(e.target.value)}
          />
          <input type="email"
            placeholder='Adresse Email'
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
          <input type="text"
            placeholder='Numéro de Téléphone'
            value={phone}
            onChange={(e) => setPhone(e.target.value)}
          />
          <textarea type="text"
            placeholder='Message :'
            id='message'
            value={message}
            onChange={(e) => setMessage(e.target.value)}
          />
          <button className='send-btn'>Envoyer</button>
        </form>
      }
    </div>
  )
}
