import React from 'react';
import "./benefits.css";
import { ReactComponent as Icon } from "../assets/vectorNB.svg";
import { ReactComponent as Satisfaction } from "../assets/satisf.svg";
import { ReactComponent as Expertise } from "../assets/expert.svg";
import { ReactComponent as Collaboration } from "../assets/collab.svg";
import { ReactComponent as Client } from "../assets/client.svg";

export default function Benefits() {
    return (
        <div className='benefits'>
            <Icon className='icon' />
            <h3>POURQUOI <span>NOUS CHOISIR</span>?</h3>
            <div className='benefits-container'>
                <div className="benefit">
                    <h5>Satisfations plus de 95%</h5>
                    <Satisfaction />
                    <p>La satisfaction de nos assurés est au centre de nos attentions</p>
                </div>
                <div className="separator"></div>
                <div className="benefit">
                    <h5>Expertise de plus de 20 ans</h5>
                    <Expertise />
                    <p>Une gestion rapide et de qualité</p>
                </div>
                <div className="separator"></div>
                <div className="benefit">
                    <h5>+300 collaborateurs en 2024</h5>
                    <Collaboration />
                    <p>Des collaborateurs dans le monde entier, pour un choix large et adapté </p>
                </div>
                <div className="separator"></div>
                <div className="benefit">
                    <h5>+20,000 Clients assurés</h5>
                    <Client />
                    <p>Des clients dans le monde entier assurer
                        et satisfait .</p>
                </div>
            </div>
        </div>
    )
}
