import React, { useEffect, useRef, useState } from 'react'
import { ReactComponent as MessageIcon } from "../assets/message.svg";
import "./message-button.css";
export default function MessageButton({isOpen, setIsOpen}) {
    
    const onClick = ()=>{
        setIsOpen(!isOpen);
    }
    const messageBtnRef = useRef()
    const handleClickOutside = (event) => {
        if (messageBtnRef.current && !messageBtnRef.current.contains(event.target)) {
          setIsOpen(false);
        }
      };
    
      useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
          document.removeEventListener('mousedown', handleClickOutside);
        };
      }, []);

    return (
        <div className={isOpen ? 'message-button-container active' : 'message-button-container'} ref={messageBtnRef}>
            <div className={isOpen ? "active" : null}>
                <div>
                    <h5>Bonjour et bienvenue chez Olassur Assurances !</h5>
                    <p>Comment puis-je vous aider aujourd'hui ? </p>
                </div>
                <input type="text" placeholder='écrivez votre message' />
            </div>
            <button className={isOpen ? "active" : null} onClick={onClick}><MessageIcon /></button>
        </div>
    )
}
