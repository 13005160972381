import "./review.css";
import { ReactComponent as SmallVector } from "../assets/small-vector.svg";
import { ReactComponent as RatingStars } from "../assets/rating-stars.svg";


export default function Review({name , srcImg, comment}) {
    
    return (
        <div className='review'>
            <SmallVector className="small-vector" />
            <div className="review-content">
                <div className="profile">
                    <img src={srcImg} alt="" />
                    <div>
                        <p>{name}</p>
                        <RatingStars />
                    </div>
                </div>
                <div>{comment}</div>
            </div>
        </div>
    )
}
