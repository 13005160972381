import React from 'react'
import Carousel from './Carousel'
import "./hero-section.css"

export default function HeroSection({currentIndex, setCurrentIndex}) {
  return (
    <div className='hero-section' >
      <div className='hero-section-container' id='herosection'>
        <Carousel currentIndex={currentIndex} setCurrentIndex={setCurrentIndex}  />
      </div>
    </div>
  )
}
