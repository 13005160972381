import React, { useEffect } from 'react'
import "./carousel.css";
import { ReactComponent as ArrowIcon } from "../assets/arrow.svg";
import { ReactComponent as ArrowIcon1 } from "../assets/arrow1.svg";
import { ReactComponent as Vector1 } from "../assets/Vector29.svg";
import { ReactComponent as Vector2 } from "../assets/Vector30.svg";
import { ReactComponent as Google } from "../assets/google.svg";
import { ReactComponent as Trustpilot } from "../assets/trustpilot.svg";
import { ReactComponent as ListIcon } from "../assets/list.svg";
export default function Carousel({currentIndex , setCurrentIndex}) {
  const images = [
    {
      img: "/images/hero-img.png",
      title: "Obtenez votre assurance avec ",
      span: "Olassur",
      title1 : ".",
      list: [
        {
          li: "Comparaison d'offres personnalisée optimisée."
        },
        {
          li: "Service client et assistance 24/7."
        },
        {
          li: "Solutions d'assurances sur-mesure."
        }
      ]
    },
    {
      img: "/images/hero-img1.png",
      img1: "/images/hero-img1-2.png",
      title: "Votre Avenir Serein : ",
      span: " Assurances et Retraite",
      title1: " Adaptées aux Seniors !",
      list: [
        {
          li: "Comparaison d'offres personnalisée optimisée."
        },
        {
          li: "Service client et assistance 24/7."
        },
        {
          li: "Solutions d'assurances sur-mesure."
        }
      ]
    },
    {
      img: "/images/hero-img2.png",
      img1: "/images/hero-img2-2.png",
      icon1: <Vector1 className='icon1' />,
      icon2: <Vector2 className='icon2' />,
      title: "Assurance ",
      span: "Étudiante,",
      title1 : " Tranquillité d'Esprit.",
      list: [
        {
          li: "Comparaison d'offres personnalisée optimisée."
        },
        {
          li: "Service client et assistance 24/7."
        },
        {
          li: "Solutions d'assurances sur-mesure."
        }
      ]
    }
  ]
  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentIndex((prevIndex) =>
        prevIndex === images.length - 1 ? 0 : prevIndex + 1
      );
    }, 5000);
    return () => clearInterval(interval);


  }, [currentIndex, images.length, setCurrentIndex]);


  return (
    <div className='carousel'>
    <Google className='rate google' />
    <Trustpilot className='rate trustpilot' />
      <div className='imgs'>
        {
          images.map((img, index) => (
            <div className={index === currentIndex ? `hero-img${index}` : `inactive`} key={index} >
              <img src={img.img} alt="" className='img img1' />
              {img.icon1}
              {img.icon2}
              <img src={img.img1} alt="" className='img img2' />
            </div>
          ))
        }
        <img src="/images/vector.png" alt="" className='vector' />
      </div>
      <div className='content-container'>
        {
          images.map((cnt, index) => (
            <div className={index === currentIndex ? "content active" : 'content'} key={index}>
              <h1 className='title-H0'>
                {cnt.title}
                <span>{cnt.span}</span>
                {cnt.title1}
              </h1>
              <ul>
                {
                  cnt.list.map((li, index) => (
                    <li key={index}><ListIcon />{li.li}</li>
                  ))
                }
              </ul>
              <button className='cta'><a href="#form">Contactez-nous <ArrowIcon className='arrow' /> <ArrowIcon1 className='arrow1' /></a> </button>
            </div>
          ))
        }
      </div>
    </div>
  )
}
