import React from 'react'
import "./newsletter.css";
import { ReactComponent as Email } from "../assets/email.svg";
import { ReactComponent as PorteEnv } from "../assets/porteEnv.svg";
import { ReactComponent as Env1 } from "../assets/env1.svg";
import { ReactComponent as Env2 } from "../assets/env2.svg";


export default function Newsletter() {
    return (
        <div className='newsletter'>
            <div className='email'>
                <Email style={{ width: "100%", height: "100%" }} />
            </div>
            <p>Abonnez-vous à notre newsletter pour recevoir des informations exclusives sur nos services d'assurance</p>
            <div className="input-container">
                <input type="text" placeholder='Adresse Email' />
                <button>Envoyer</button>
            </div>
            <div className='porteEnv'>
                <PorteEnv style={{ width: "100%", height: "100%" }} />
            </div>
            <div className='env1'>
                <Env1 style={{ width: "100%", height: "100%" }} />
            </div>
            <div className='env2'>
                <Env2 style={{ width: "100%", height: "100%" }} />
            </div>
        </div>
    )
}
