import "./type.css";
import { ReactComponent as TypeIcon } from "../assets/type.svg";
import { ReactComponent as DarkBlue } from "../assets/darkBlue.svg";
import { ReactComponent as LightBlue } from "../assets/lightBlue.svg";
import { ReactComponent as Yellow } from "../assets/yellow.svg";
import { ReactComponent as Pink } from "../assets/pink.svg";
import { ReactComponent as Orange } from "../assets/orange.svg";
import { ReactComponent as Purple } from "../assets/purple.svg";
import { ReactComponent as PurpleG } from "../assets/purpleG.svg";
import { ReactComponent as OrangeG } from "../assets/orangeG.svg";
import { ReactComponent as PinkG } from "../assets/pinkG.svg";
import { ReactComponent as YellowG } from "../assets/yellowG.svg";
import { ReactComponent as LightBlueG } from "../assets/lightBlueG.svg";
import { ReactComponent as DarkBlueG } from "../assets/darkBlueG.svg";

export default function Type() {
    const types = [
        {
            title: "Assurance Vie",
            icon: <DarkBlue className="bg-img1" />,
            iconActive: <DarkBlueG className="bg-img2" />,
            srcImg: "/images/vie.png",
            descp: "Protéger l'avenir de vos proches avec une couverture financière en cas de décès, d'invalidité ou de maladie grave.",
            bgColor : "var(--dark-blue-95)",
            titleColor : "var(--dark-blue-30)",
            paragColor: "var(--dark-blue-20)",
        },
        {
            title: "Assurance Santé",
            icon: <LightBlue className="bg-img1" />,
            iconActive: <LightBlueG className="bg-img2" />,
            srcImg: "/images/sante.png",
            descp: "Offrez-vous une couverture complète pour tous vos besoins médicaux.",
            bgColor : "var(--blue-normal-95)",
            titleColor : "var(--blue-normal-30)",
            paragColor: "var(--blue-normal-30)",
        },
        {
            title: "Assurance Voiture",
            icon: <Yellow className="bg-img1" />,
            iconActive: <YellowG className="bg-img2" />,
            srcImg: "/images/voiture.png",
            descp: "Protégez votre voiture avec une couverture contre les accidents, le vol et les dommages matériels.",
            bgColor : "var(--yellow-normal-95)",
            titleColor : "var(--yellow-dark-30)",
            paragColor : "var(--yellow-normal-20)"
        },
        {
            title: "Assurance Retraite",
            icon: <Orange className="bg-img1" />,
            iconActive: <OrangeG className="bg-img2" />,
            srcImg: "/images/retraite.png",
            descp: "Préparez votre avenir avec des solutions d'épargne et de rente pour une retraite sereine.",
            bgColor : "var(--yellow-dark-95)",
            titleColor : "var(--yellow-dark-30)",
            paragColor : "var(--yellow-dark-20)"
        },
        {
            title: "Assurance Animals",
            icon: <Purple className="bg-img1" />,
            iconActive: <PurpleG className="bg-img2" />,
            srcImg: "/images/animals.png",
            descp: "Couverture pour les frais vétérinaires, les traitements et les interventions chirurgicales de vos animaux.",
            bgColor : "var(--purple-light-95)",
            titleColor : "#380F8A",
            paragColor : "#250A5C"
        },
        {
            title: "Assurance Voyage",
            icon: <Pink className="bg-img1" />,
            iconActive: <PinkG className="bg-img2" />,
            srcImg: "/images/voyage.png",
            descp: "Voyagez l'esprit tranquille avec une assurance qui couvre les urgences médicales, les annulations de voyage et les pertes de bagages.",
            bgColor : "var(--pink-light-95)",
            titleColor : "#8A0F38",
            paragColor : "#660022"
        },
    ]
    return (
        <div className="type-assurance" id="types">
            <h3 className="title"><TypeIcon /> Type d'assurance </h3>
            <div className="types">
                {
                    types.map((type, index) => (
                        <div className="type" key={index}>
                            <div className="img-type">
                                {type.icon}
                                {type.iconActive}
                                <img src={type.srcImg} alt="" />
                            </div>
                            <div className="content-type" style={{backgroundColor: type.bgColor}}>
                                <p className="title-type" style={{color: type.titleColor}}>{type.title}</p>
                                <p className="descp-type" style={{color: type.paragColor}}>{type.descp}</p>
                            </div>
                        </div>
                    ))
                }
            </div>
        </div>
    )
}
