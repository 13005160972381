import { ReactComponent as Facebook } from "../assets/facebook.svg";
import { ReactComponent as Twitter } from "../assets/twitter.svg";
import { ReactComponent as Instagram } from "../assets/instagram.svg";
import { ReactComponent as LinkedIn } from "../assets/linkedin.svg";
import "./footer.css";
import { useEffect } from "react";

export default function Footer() {
    const classList = ["line"];
    useEffect(() => {
        const line = document.querySelector(".line");
        setTimeout(() => {
            line.classList.remove("frame-3");
            line.classList.add("frame-1");
        }, 1000)
        setTimeout(() => {
            line.classList.remove("frame-1");
            line.classList.add("frame-2");
        }, 2000)
        setTimeout(() => {
            line.classList.remove("frame-2");
            line.classList.add("frame-3");
        }, 3000)
    })
    return (
        <div className='footer'>
            <div className={classList.join(" ")}></div>
            <div className="logo">
                <a href="/">
                    <img src="/images/logo.png" alt="" />
                </a>
                <p>© 2024 Olassur Assurances. </p>
            </div>
            <ul className="navigation">
                <li><a href="/">Accueil
                    <div className="separator"></div>
                </a></li>
                <li><a href="#services">Nos Services
                    <div className="separator"></div>
                </a></li>
                <li><a href="#faq">FAQ
                    <div className="separator"></div>
                </a></li>
                <li><a href="/">
                    Politique de Confidentialité
                    <div className="separator"></div>
                </a></li>
            </ul>
            <div className="separator"></div>
            <div className="infos">
                <p>Téléphone: +33 1 23 45 67 89</p>
                <p>Email: contact@olassur.fr</p>
                <p>Adresse: 123 Rue de l'Assurance, 75001 Paris, France</p>
            </div>
            <div className="separator"></div>
            <div className="social-media">
                <p>Suivez-nous sur
                    <div className="underline"></div>
                </p>
                <ul>
                    <li><a href="https://www.facebook.com/"><Facebook /></a></li>
                    <li><a href="https://twitter.com/"><Twitter /></a></li>
                    <li><a href="https://www.instagram.com/"><LinkedIn /></a></li>
                    <li><a href="https://www.linkdin.com/"><Instagram /></a></li>
                </ul>
            </div>
        </div>
    )
}
