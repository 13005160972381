import "./partner.css";


export default function Partner() {
    return (
        <div className="partner" id="wellcompare">
            <div className="partner-header">
                <h4>Officiel Partenaire</h4>
                <div className="separator"></div>
            </div>
            <div className="partner-container">
                <div>
                    <p>Le comparateur d'assurances<span> le plus performant</span> sur le marché !</p>
                    <button><a href="https://wellcompare.com">Visitez WellCompare</a></button>
                </div>
                <img src="/images/wellcompareLogo.png" alt="" className="logo"/>
            </div>
        </div>
    )
}
