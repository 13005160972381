import React, { useCallback, useEffect, useRef } from 'react'
import { ReactComponent as TelephoneIcon } from "../assets/telephone.svg";
import "./call-button.css";

export default function CallButton({isOpen, setIsOpen}) {
  // const [isOpen, setIsOpen] = useState(false);
  const onClick = () => {
    setIsOpen(!isOpen);
  }
  const telephoneBtnRef = useRef()
  const handleClickOutside = useCallback((event) => {
    if (telephoneBtnRef.current && !telephoneBtnRef.current.contains(event.target)) {
      setIsOpen(false);
    }
  },[setIsOpen]);

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [handleClickOutside]);
  return (
    <div className={isOpen ? 'call-button-container active' : 'call-button-container'} ref={telephoneBtnRef}>
      <div className={isOpen ? "active" : null}>
        <p>Appelez-nous</p>
        <p>+33780432192</p>
      </div>
      <button className={isOpen ? "active" : null} onClick={onClick}><TelephoneIcon /></button>
    </div>
  )
}
