import React, { useEffect, useState } from 'react'
import "./nav-bar.css";
import DropDown from './DropDown';
import DropDownClient from './DropDownClient';

export default function NavBar({isOpen, setIsOpen, setCurrentIndex }) {

    const classList = ["nav-bar-container"]

    const [scrollPosition, setScrollPosition] = useState(0);
    
    useEffect(() => {
        const handleScroll = () => {
            setScrollPosition(window.scrollY);
        };
        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);
    
    if (scrollPosition > 880) {
        classList.push("white-nav-bar")
    }
    const types = [
        { id: 1, label: 'Vie' },
        { id: 2, label: 'Automobile' },
        { id: 3, label: 'Santé' },
        { id: 4, label: 'Voyage' },
        { id: 5, label: 'Anmimaux' },
        { id: 6, label: 'Retraite' }
    ];
    const clients = [
        {
            id: 0,
            label: "Actif"
        },
        {
            id: 1,
            label: "Sénior"
        },
        {
            id: 2,
            label: "étudient"
        }
    ]

    const onOpenNavBar = () => {
        setIsOpen(!isOpen)
    }
    const OnClose = ()=>{
        setIsOpen(false)
    }
    return (
        <div className="nav-bar-container-inner">
            <div className={classList.join(" ")}>
                <div className='nav-bar'>
                    <DropDown options={types} scrollY={scrollPosition} />
                    <DropDownClient options={clients} setCurrentIndex={setCurrentIndex} scrollY={scrollPosition} />
                </div>
                <div className='logo'>
                    <a href="/">
                        <img src={scrollPosition > 800 ? "/images/olassurLogo.png" : "/images/olassurLogoWhite.png"} alt="" />
                    </a>
                </div>
                <div className='nav-bar'>
                    <button className={scrollPosition > 800 ? 'contact white' : 'contact'}><a href="#form">Appel à un expert</a></button>
                    <button className='wellcomapreBtn'><a href="#wellcompare">WellCompare</a></button>
                </div>
                <div className="nav-bar-phone">
                    <div className={isOpen ? "menu-icon open" : 'menu-icon'} onClick={onOpenNavBar}>
                        <div className='div1'></div>
                        <div className='div2'></div>
                        <div className='div3'></div>
                    </div>
                    {
                        isOpen &&
                        <div className='nav-bar-list'>
                            <button className='contact' onClick={OnClose}><a href="#form">Appel à un expert</a></button>
                            <button className='wellcomapreBtn' onClick={OnClose}><a href="#wellcompare">WellCompare</a></button>
                            <DropDown options={types} onclose={setIsOpen} />
                            <DropDownClient options={clients} setCurrentIndex={setCurrentIndex} onclose={setIsOpen}  />
                        </div>
                    }
                </div>
            </div>
        </div>
    )
}
