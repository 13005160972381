import './App.css';
import HeroSection from './Components/HeroSection';
import './global.css';
import './reset.css';
import './classes.css';
// import Tabs from './Components/Tabs';
import Type from './Components/Type';
import Benefits from './Components/Benefits';
import Reviews from './Components/Reviews';
import Partner from './Components/Partner';
import NavBar from './Components/NavBar';
import Newsletter from './Components/Newsletter';
import FAQ from './Components/FAQ';
import Form from './Components/Form';
import Footer from './Components/Footer';
import { useState } from 'react';
import CallButton from './Components/CallButton';
import MessageButton from './Components/MessageButton';

function App() {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [isOpen, setIsOpen] = useState(false);
  const [isMessageOpen , setIsMessageOpen] = useState(false);
  const [isAppelOpen , setIsAppelOpen] = useState(false);
  return (
    <div className="App">
    {
      isOpen &&
      <div className="overlay"></div>
    }
      <NavBar setCurrentIndex={setCurrentIndex} isOpen={isOpen} setIsOpen={setIsOpen} />
      <div className={isAppelOpen || isMessageOpen ? 'sticky active' :'sticky'}>
      <MessageButton isOpen={isMessageOpen} setIsOpen={setIsMessageOpen} />
      <CallButton setIsOpen={setIsAppelOpen} isOpen={isAppelOpen} />
      </div>
      <HeroSection currentIndex={currentIndex} setCurrentIndex={setCurrentIndex} />
      <Benefits />
      <Type />
      {/* <Tabs /> */}
      <Reviews />
      <FAQ />
      <div className='container'>
        <Newsletter />
        <Partner />
      </div>
      <Form />
      <Footer />
    </div>
  );
}

export default App;
