import React, { useEffect, useRef, useState } from 'react';
import './drop-down.css';
import { ReactComponent as CaretDown } from "../assets/caretDown.svg";

export default function DropDown({ options, scrollY, onclose }) {
    const [isOpen, setIsOpen] = useState(false);
    const [selectedOption, setSelectedOption] = useState(null);

    const toggleDropdown = () => {
        setIsOpen(!isOpen);
    };

    const handleOptionClick = (option) => {
        setSelectedOption(option);
        setIsOpen(false);
    };

    const classList = ["dropdown-toggle"]
    if (isOpen) {
        classList.push("open")
    }
    if (scrollY > 800) {
        classList.push("white")
    }
    const dropDownRef = useRef()
    const handleClickOutside = (event) => {
        if (dropDownRef.current && !dropDownRef.current.contains(event.target)) {
            setIsOpen(false);
        }
    };

    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);
    return (
        <div className="dropdown" ref={dropDownRef}>
            <div className={classList.join(" ")} onClick={toggleDropdown}>
                {selectedOption || 'Assurance'}
                <CaretDown className={isOpen ? 'caret Up' : 'caret'} />
            </div>
            {isOpen && (
                <ul className="dropdown-menu">
                    {options.map((option) => (
                        <li key={option.id} onClick={() => {
                            handleOptionClick(option.label);
                            if (!(typeof onclose === "function")) {
                                return;
                            }
                            onclose(false)
                        }}>
                            <a href="#types">{option.label}</a>
                        </li>
                    ))}
                </ul>
            )}
        </div>
    );
};

