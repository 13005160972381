import Review from "./Review"
import "./reviews.css"
import reviews from "../data/reviews.json"
import { useRef, useState } from "react";

export default function Reviews() {
  // const [currentReviewIndices, setCurrentReviewIndices] = useState(
  //   Array.from({ length: 3 }, (_, index) => index)
  // );

  // useEffect(() => {
  //   const intervalId = setInterval(() => {
  //     setCurrentReviewIndices(prevIndices =>
  //       prevIndices.map((index, i) =>
  //         index === reviews.length - 1 ? 0 : index + 1
  //       )
  //     );
  //   }, 4000);

  //   return () => clearInterval(intervalId);
  // }, []);

  const scrollRef = useRef(null);
  const [isDragging, setIsDragging] = useState(false);
  const [startX, setStartX] = useState(0);
  const [scrollLeft, setScrollLeft] = useState(0);

  const handleMouseDown = (e) => {
    setIsDragging(true);
    scrollRef.current.style.cursor = 'grabbing';
    setStartX(e.pageX - scrollRef.current.offsetLeft);
    setScrollLeft(scrollRef.current.scrollLeft);
  };

  const handleMouseLeaveOrUp = () => {
    setIsDragging(false);
    scrollRef.current.style.cursor = 'grab';
  };

  const handleMouseMove = (e) => {
    if (!isDragging) return;
    e.preventDefault();
    const x = e.pageX - scrollRef.current.offsetLeft;
    const walk = (x - startX) * 2; // Adjust scrolling speed by multiplying
    scrollRef.current.scrollLeft = scrollLeft - walk;
  };
  return (
    <div className='reviews'>
      <h3>Nous avons <span>la confiance</span> de <span>nos clients</span>.</h3>
      <div className="reviews-container"
        ref={scrollRef}
        onMouseDown={handleMouseDown}
        onMouseLeave={handleMouseLeaveOrUp}
        onMouseUp={handleMouseLeaveOrUp}
        onMouseMove={handleMouseMove}
      >
        {
          reviews.map((review, i) => (
            <Review key={i} name={review.name} comment={review.comment} srcImg={review.srcImg} />
          ))
        }
        {/* <div className="overlay"></div> */}
      </div>
      {/* <Review /> */}
    </div>
  )
}
