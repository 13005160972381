import React, { useState } from 'react'
import "./faq.css";
import Accordion from './Accordion';
import Faqs from "../data/faq.json"

export default function FAQ() {
    const [activeIndex, setActiveIndex] = useState()
    const handleClick = (index) => {
        setActiveIndex((prev) => prev !== index && index)
    }
    return (
        <div className='faq-container'>
            <div className='faq-header'>
                <h3>FAQ</h3>
                <div className='underline'>
                    <div className='dark-b'></div>
                    <div className='light-b'></div>
                    <div className='normal-y'></div>
                    <div className='dark-y'></div>
                </div>
            </div>
            <div className='faq'>
                <div className='faqs'>
                    {
                        Faqs.map((faq, index) => (
                            <Accordion  key={index} theme={faq.theme} index={index + 1} question={faq.question} handleClick={()=>handleClick(index)} open={index === activeIndex}>
                                <p>{faq.answer}</p>
                            </Accordion>
                        ))
                    }
                </div>
                <img src="/images/faqs.png" alt="" />
            </div>
        </div>
    )
}
