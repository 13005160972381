import React, { Children } from 'react'
import "./accordion.css"

export default function Accordion({ index, question, theme, handleClick, open , children}) {

    return (
        <div className='accordion-container'>
            <div className='accordion-header' onClick={handleClick}>
                <div className={open ? `number ${theme} active` : `number ${theme}`}>{index}</div>
                <div className={open ? `content ${theme} active` : `content ${theme}`}>{question}</div>
            </div>
            {
                open &&
                <div className={`accordion-body ${theme}`}>
                    <div className='line'></div>
                    <div className={`answer ${theme}`}>
                        {
                            Children.map(children, child => <>{child}</>
                            )
                        }
                    </div>
                </div>
            }
        </div>
    )
}

// import { Children } from "react";
// import "./accordion.css";


// export default function Accordion({ handleClick, open, title, theme, children, openStyle }) {
//     const classList = ["accordion", theme, openStyle]
//     return (
//         <>
//             <div className={classList.join(" ")} onClick={handleClick}><h3 className="ovoky-text-style-title-H3">{title}</h3>
//                 <div className={open === true ? "bars-icon active" : "bars-icon"}>
//                     <span className="bar-icon"></span>
//                     <span className="bar-icon"></span>
//                 </div>
//             </div>
//             <>
//                 {
//                     open &&
//                     <>
//                         {
//                             Children.map(children, child => <>{child}</>
//                             )
//                         }
//                     </>
//                 }

//             </>
//         </>
//     )
// }

// }
