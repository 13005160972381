import React, { useEffect, useRef, useState } from 'react';
import './dropdownclient.css';
import { ReactComponent as CaretDown } from "../assets/caretDown.svg";

export default function DropDownClient({ options, setCurrentIndex, scrollY, onclose }) {
    const [isOpen, setIsOpen] = useState(false);
    const [selectedOption, setSelectedOption] = useState(null);

    const toggleDropdown = () => {
        setIsOpen(!isOpen);
    };

    const handleOptionClick = (option) => {
        setSelectedOption(option);
        setIsOpen(false);
    };
    const onSelect = (index) => {
        setCurrentIndex(index)
    }
    const classList = ["dropdown-toggle"]
    if (isOpen) {
        classList.push("open")
    }
    if (scrollY > 800) {
        classList.push("white")
    }

    const dropDownClientRef = useRef()
    const handleClickOutside = (event) => {
        if (dropDownClientRef.current && !dropDownClientRef.current.contains(event.target)) {
            setIsOpen(false);
        }
    };

    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    return (
        <div className="dropdownclient" ref={dropDownClientRef} >
            <div className={classList.join(" ")} onClick={toggleDropdown}>
                {selectedOption || 'Client'}
                <CaretDown className={isOpen ? 'caret Up' : 'caret'} />
            </div>
            {isOpen && (
                <ul className="dropdown-menu">
                    {options.map((option, index) => (
                        <li key={index} onClick={() => {
                            handleOptionClick(option.label);
                            onSelect(option.id);
                            if (!(typeof onclose === "function")) {
                                return;
                            }
                            onclose(false)
                        }}>
                            <a href="#herosection">{option.label}</a>
                        </li>
                    ))}
                </ul>
            )}
        </div>
    );
};

